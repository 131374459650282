<script setup>
  import { ref, computed } from 'vue';
  import LogoSVGWhite from '@/Components/LogoSVGWhite.vue';
  import { Link } from '@inertiajs/vue3';

  const isLoggedIn = ref(false);

  const navLinksForVisitors = [
    { text: 'About Us', href: '/about' },
    { text: 'Profiles', href: '/visitor-profiles-page' },
    { text: 'Gigs', href: '/visitor-gigs-page' },
    { text: 'Messaging', href: '/waitlist?p=messaging' },
    { text: 'Registration', href: '/waitlist' },
    { text: 'Login', href: '/login' }
  ];
  // TODO: ADD REAL LINKS:
  const navLinksForUsers = computed(() => [
    { text: 'Gigs Hub', href: '/user/gigs', disabled: !isLoggedIn.value },
    { text: 'All gigs', href: '/gigs', disabled: !isLoggedIn.value },
    {
      text: 'Create Profile',
      href: '/show-profile',
      disabled: !isLoggedIn.value
    },
    { text: 'Manage Calendar', href: '/#', disabled: !isLoggedIn.value },
    { text: 'Events', href: '/#', disabled: !isLoggedIn.value }
  ]);
</script>

<template>
  <div class="bg-black text-white">
    <div class="mx-auto flex max-w-4xl flex-col p-2 pt-20 md:flex-row">
      <!-- <img :src="LogoSVG" alt="Line204524" class="homepage-line20" /> -->
      <div class="w-1/5 md:w-2/5">
        <!-- <LogoSVGWhite class="w-44" /> -->
        <Link :href="'/'" class="flex items-center gap-1">
          <!-- Spicygigs Logo -->
          <div class="w-1/5">
            <img
              src="/images/spicy-gigs-logo-transparent.png"
              alt="Spicygigs Logo"
              class="object-contain"
            />
          </div>
          <!-- LogoSVG or dynamic component -->
          <LogoSVGWhite class="w-4/5 md:-ml-6" />
        </Link>
        <div class="flex w-60 flex-wrap text-xs">
          <p>
            Spicygigs is committed to providing creators with the tools to
            thrive. Have questions? Reach out to our support team.
          </p>
        </div>
        <div class="">
          <p class="mt-4 text-xs font-semibold">Follow Us On</p>
          <div class="mt-2 flex flex-row gap-3">
            <div
              class="flex aspect-square h-8 w-8 items-center justify-center rounded-full bg-purple-500"
              v-show="false"
            >
              <i class="fa-brands fa-facebook text-white"></i>
            </div>
            <div
              class="flex aspect-square h-8 w-8 items-center justify-center rounded-full bg-purple-500"
            >
              <a href="https://www.instagram.com/myspicygigs/" target="_blank">
                <i class="fa-brands fa-square-instagram text-white"></i>
              </a>
            </div>
            <div
              class="flex aspect-square h-8 w-8 items-center justify-center rounded-full bg-purple-500"
              v-show="false"
            >
              <i class="fa-brands fa-linkedin text-white"></i>
            </div>
            <div
              class="flex aspect-square h-8 w-8 items-center justify-center rounded-full bg-purple-500"
            >
              <a href="https://x.com/myspicygigs" target="_blank">
                <i class="fa-brands fa-x-twitter text-white"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-row text-sm md:w-3/5">
        <div class="flex w-1/4 flex-col">
          <p class="mb-3 font-semibold">Visitors</p>
          <ul>
            <li v-for="(link, index) in navLinksForVisitors" :key="index">
              <a
                :href="link.href"
                class="!text-white hover:!text-white hover:underline"
              >
                {{ link.text }}
              </a>
            </li>
          </ul>
        </div>
        <!-- <div class="flex flex-col w-1/4 mr-4">
          <p class="mb-3 font-semibold">Creators/Models</p>
          <ul>
            <li v-for="(link, index) in navLinksForUsers" :key="index">
              <a :href="link.href" :class="{ disabled: link.disabled }">
                {{ link.text }}
              </a>
            </li>
          </ul>
        </div> -->
        <div class="mr-4 flex w-1/4 flex-col">
          <p class="mb-3 font-semibold">Creators/Models</p>
          <ul>
            <li v-for="(link, index) in navLinksForUsers" :key="index">
              <a
                :href="link.href"
                class="!text-white"
                :class="[
                  'block',
                  link.disabled
                    ? 'pointer-events-none cursor-not-allowed text-gray-500'
                    : 'text-white hover:text-orange-500'
                ]"
              >
                {{ link.text }}
              </a>
            </li>
          </ul>
        </div>

        <div class="flex w-1/4 flex-col">
          <p class="mb-3 font-semibold">Company</p>
          <ul>
            <li class="homepage-text62 TypographyBodyText">
              <Link
                class="!text-white hover:!text-white hover:underline"
                href="/welcome-page"
              >
                Welcome
              </Link>
            </li>
            <li class="homepage-text63 TypographyBodyText">
              <Link
                class="!text-white hover:!text-white hover:underline"
                href="/about"
              >
                About
              </Link>
            </li>
            <!-- <li class="homepage-text64 TypographyBodyText">Partners</li> -->
            <li class="homepage-text65 TypographyBodyText">
              <a
                href="mailto:contact@spicygigs.com"
                class="!text-white hover:!text-white hover:underline"
              >
                Contact
              </a>
            </li>
            <!-- <li class="homepage-text66 TypographyBodyText">Calendar</li> -->
            <!-- <li class="homepage-text67 TypographyBodyText">Testimonials</li> -->
          </ul>
        </div>
        <div class="flex w-1/4 flex-col">
          <p class="mb-3 font-semibold">Legal</p>
          <ul>
            <li class="">
              <Link
                href="/docs/terms-of-service"
                class="!text-white hover:!text-white hover:underline"
              >
                Terms Of Service
              </Link>
            </li>
            <li>
              <Link
                href="/docs/privacy-policy"
                class="!text-white hover:!text-white hover:underline"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <hr class="mx-auto mt-10 w-5/6" />
    <p class="my-4 text-center text-sm">
      © 2024 Copyright Spicygigs, All Rights Reserved
    </p>
  </div>
</template>

<style scoped></style>
